@tailwind base;
@tailwind components;
@tailwind utilities;

/* '*' {
  margin-bottom: 0rem;
  padding-bottom: 0rem;
  margin-left: 2rem;
  margin-right: 2rem;
} */

@font-face {
  font-family: "sf";
  src: url("./fonts/sf/SFPRODISPLAYREGULAR.OTF") format("truetype");
}

body {
  margin: 0;
  font-family: "sf";
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.iframe-home {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 0;
}

.top-btn-absolute-text {
  top: 40%;
}

.btn-bt {
  background-color: black;
  opacity: 60%;
}

.top-btn-absolute {
  top: 84%;
}

.text-op-0 {
  z-index: 9999;
}

.max-width-video {
  max-width: 1024px;
}

.mg-footer {
  margin-left: 0;
  margin-right: 0;
}

.body-map {
  height: 100%;
  margin: 0;
}

.img-map {
  width: 100%;
  height: auto;
  max-width: 1024px;
  min-width: 300px;
}

.absolute-btn {
  position: absolute;
  left: 12%;
  right: 0%;
  top: 80%;
}

.model-contact {
  background-color: black;
  opacity: 95%;
}

.img-icon {
  width: 100%;
  height: 100%;
}

/* Map */
.img-map {
  width: 100%;
  height: 100%;
}

.absolute-mark-Pavillion {
  position: absolute;
  left: 56%;
  top: 29%;
}

.absolute-mark-PoolVilla {
  position: absolute;
  left: 43%;
  top: 42.5%;
}

.absolute-mark-RAKxaJAI {
  position: absolute;
  left: 35%;
  top: 63%;
}

.absolute-mark-VitalLife {
  position: absolute;
  left: 45.1%;
  top: 63%;
}

.absolute-mark-RAKxaGAYA {
  position: absolute;
  left: 54.4%;
  top: 70.5%;
}

.map-absolute {
  left: 30%;
  top: 17%;
}

.mark-img {
  width: auto;
  height: 50px;
}

.btn-map {
  background-color: black;
  opacity: 70%;
}

@media screen and (max-width: 2560px) {
  .top-map {
    top: 10%;
  }

  .img-map {
    width: 1500px;
  }

  .absolute-btn {
    position: absolute;
    left: 20%;
    right: 0%;
    top: 80%;
  }

  .top-btn-absolute-text {
    top: 40%;
  }

  /* Map */
  .img-map {
    width: 100%;
    height: 100%;
  }

  .absolute-mark-Pavillion {
    position: absolute;
    left: 56%;
    top: 29%;
  }

  .absolute-mark-PoolVilla {
    position: absolute;
    left: 43%;
    top: 42.5%;
  }

  .absolute-mark-RAKxaJAI {
    position: absolute;
    left: 35%;
    top: 63%;
  }

  .absolute-mark-VitalLife {
    position: absolute;
    left: 45.1%;
    top: 63%;
  }

  .absolute-mark-RAKxaGAYA {
    position: absolute;
    left: 54.4%;
    top: 70.5%;
  }

  .map-absolute {
    left: 30%;
    top: 17%;
  }

  .mark-img {
    width: auto;
    height: 50px;
  }
}

/* VR */
@media screen and (max-width: 2000px) {
  .top-map {
    top: 10%;
  }

  .img-map {
    width: 1500px;
  }

  .absolute-btn {
    position: absolute;
    left: 20%;
    right: 0%;
    top: 75%;
  }

  .top-btn-absolute-text {
    top: 40%;
  }

  /* Map */
  .img-map {
    width: 85%;
    height: 100%;
  }

  .map-absolute {
    left: 27%;
    top: 4.6%;
  }

  .mark-img {
    width: auto;
    height: 50px;
  }

  .absolute-mark-Pavillion {
    position: absolute;
    left: 54.5%;
    top: 18.5%;
  }

  .absolute-mark-PoolVilla {
    position: absolute;
    left: 40.7%;
    top: 35.5%;
  }

  .absolute-mark-RAKxaJAI {
    position: absolute;
    left: 32%;
    top: 60.5%;
  }

  .absolute-mark-VitalLife {
    position: absolute;
    left: 42.8%;
    top: 60.5%;
  }

  .absolute-mark-RAKxaGAYA {
    position: absolute;
    left: 53%;
    top: 70.5%;
  }
}

@media screen and (max-width: 1440px) {
  .top-map {
    top: 3%;
  }

  .img-map {
    width: 700px;
  }

  .absolute-btn {
    position: absolute;
    left: 12%;
    right: 0%;
    top: 76%;
  }

  .top-btn-absolute-text {
    top: 40%;
  }

  /* Map */
  .map-absolute {
    left: 25%;
    top: 6.5%;
  }

  .mark-img {
    width: auto;
    height: 35px;
    margin-left: 4rem !important;
  }

  .absolute-mark-Pavillion {
    position: absolute;
    left: 55.3%;
    top: 20%;
  }

  .absolute-mark-PoolVilla {
    position: absolute;
    left: 39%;
    top: 35.5%;
  }

  .absolute-mark-RAKxaJAI {
    position: absolute;
    left: 29.4%;
    top: 60%;
  }

  .absolute-mark-VitalLife {
    position: absolute;
    left: 41.6%;
    top: 61%;
  }

  .absolute-mark-RAKxaGAYA {
    position: absolute;
    left: 52.8%;
    top: 70.5%;
  }
}

@media screen and (max-width: 1024px) {
  .top-map {
    top: 3%;
  }

  .img-map {
    width: 700px;
  }

  .absolute-btn {
    position: absolute;
    left: 12%;
    right: 0%;
    top: 75%;
  }

  .top-btn-absolute-text {
    top: 40%;
  }

  /* Map */
  .map-absolute {
    left: 15%;
    top: 3.5%;
  }

  .mark-img {
    width: auto;
    height: 35px;
    margin-left: 4rem !important;
  }

  .absolute-mark-Pavillion {
    position: absolute;
    left: 56.8%;
    top: 17.5%;
  }

  .absolute-mark-PoolVilla {
    position: absolute;
    left: 35%;
    top: 35%;
  }

  .absolute-mark-RAKxaJAI {
    position: absolute;
    left: 21.2%;
    top: 61.5%;
  }

  .absolute-mark-VitalLife {
    position: absolute;
    left: 38.4%;
    top: 61.5%;
  }

  .absolute-mark-RAKxaGAYA {
    position: absolute;
    left: 54%;
    top: 71%;
  }
}

@media screen and (max-width: 768px) {
  .top-map {
    top: 3%;
  }

  .img-map {
    width: 700px;
  }

  .absolute-btn {
    position: absolute;
    left: 38%;
    right: 0%;
    top: 69%;
  }

  .top-btn-absolute-text {
    top: 35%;
  }

  /* Map */
  .map-absolute {
    left: 4.5%;
    top: 12%;
  }

  .mark-img {
    width: auto;
    height: 30px;
    margin-left: 2.8rem !important;
  }

  .absolute-mark-Pavillion {
    position: absolute;
    left: 63%;
    top: 23.5%;
  }

  .absolute-mark-PoolVilla {
    position: absolute;
    left: 33.8%;
    top: 37.7%;
  }

  .absolute-mark-RAKxaJAI {
    position: absolute;
    left: 15.8%;
    top: 60%;
  }

  .absolute-mark-VitalLife {
    position: absolute;
    left: 38.7%;
    top: 60%;
  }

  .absolute-mark-RAKxaGAYA {
    position: absolute;
    left: 59.5%;
    top: 65.5%;
  }
}

@media screen and (max-width: 425px) {
  /* Btn-Group */
  .absolute-group {
    position: absolute;
    left: 85%;
    right: 0%;
    top: 80%;
  }

  .top-Group-absolute {
    top: 3.5%;
  }

  .top-map {
    top: 28%;
  }

  .img-map {
    width: 400px;
  }

  .absolute-btn {
    position: absolute;
    left: 25%;
    right: 0%;
    top: 63%;
  }

  .top-btn-absolute-text {
    top: 35%;
  }

  /* Map */
  .img-map {
    width: 95%;
    height: 100%;
  }

  .map-absolute {
    left: 4.7%;
    top: 20%;
  }

  .mark-img {
    width: auto;
    height: 30px;
    margin-left: 2.2rem !important;
  }

  .text-sx {
    font-size: 11px;
  }

  .absolute-mark-Pavillion {
    position: absolute;
    left: 57.5%;
    top: 24.5%;
  }

  .absolute-mark-PoolVilla {
    position: absolute;
    left: 28%;
    top: 36.5%;
  }

  .absolute-mark-RAKxaJAI {
    position: absolute;
    left: 9%;
    top: 51.5%;
  }

  .absolute-mark-VitalLife {
    position: absolute;
    left: 34%;
    top: 51.5%;
  }

  .absolute-mark-RAKxaGAYA {
    position: absolute;
    left: 53%;
    top: 59%;
  }
}

@media screen and (max-width: 375px) {
  /* Btn-Group */
  .absolute-group {
    position: absolute;
    left: 83.5%;
    right: 0%;
    top: 80%;
  }

  .top-Group-absolute {
    top: 3%;
  }

  .top-map {
    top: 30%;
  }

  .img-map {
    width: 350px;
  }

  .absolute-btn {
    position: absolute;
    left: 22%;
    right: 0%;
    top: 69%;
  }

  .top-btn-absolute-text {
    top: 35%;
  }

  /* Map */
  .img-map {
    width: 95%;
    height: 100%;
  }

  .map-absolute {
    left: 4.7%;
    top: 20%;
  }

  .mark-img {
    width: auto;
    height: 30px;
    margin-left: 2.2rem !important;
  }

  .text-sx {
    font-size: 10px;
  }

  .absolute-mark-Pavillion {
    position: absolute;
    left: 56%;
    top: 24%;
  }

  .absolute-mark-PoolVilla {
    position: absolute;
    left: 27.5%;
    top: 33%;
  }

  .absolute-mark-RAKxaJAI {
    position: absolute;
    left: 6%;
    top: 45.5%;
  }

  .absolute-mark-VitalLife {
    position: absolute;
    left: 34%;
    top: 45.5%;
  }

  .absolute-mark-RAKxaGAYA {
    position: absolute;
    left: 53%;
    top: 51.5%;
  }
}

@media screen and (max-width: 325px) {
  /* Btn-Group */
  .absolute-group {
    position: absolute;
    left: 80%;
    right: 0%;
    top: 80%;
  }

  .top-Group-absolute {
    top: 4.5%;
  }

  .btn-group-close {
    position: absolute;
    left: 18%;
    right: 0%;
    top: 47%;
  }

  .top-map {
    top: 31%;
  }

  .img-map {
    width: 300px;
  }

  .absolute-btn {
    position: absolute;
    left: 18%;
    right: 0%;
    top: 47%;
  }

  .top-btn-absolute-text {
    top: 35%;
  }

  /* Map */
  .img-map {
    width: 95%;
    height: 100%;
  }

  .map-absolute {
    left: 3.2%;
    top: 17%;
  }

  .mark-img {
    width: auto;
    height: 30px;
    margin-left: 2.2rem !important;
  }

  .text-sx {
    font-size: 10px;
  }

  .absolute-mark-Pavillion {
    position: absolute;
    left: 56%;
    top: 22%;
  }

  .absolute-mark-PoolVilla {
    position: absolute;
    left: 26.5%;
    top: 34.5%;
  }

  .absolute-mark-RAKxaJAI {
    position: absolute;
    left: 3.5%;
    top: 55%;
  }

  .absolute-mark-VitalLife {
    position: absolute;
    left: 34%;
    top: 55%;
  }

  .absolute-mark-RAKxaGAYA {
    position: absolute;
    left: 54%;
    top: 63%;
  }
}